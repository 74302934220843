import { VFC } from "react";

import { Text } from "theme-ui";

import { IntercomShowNewMessage } from "src/components/vendor";
import * as analytics from "src/lib/analytics";
import { Link } from "src/ui/link";
import { Section } from "src/ui/section";

type Props = {
  category: string;
};

export const RequestDestination: VFC<Readonly<Props>> = ({ category }) => {
  return (
    <Section sx={{ height: "max-content" }}>
      <Text sx={{ fontSize: 1, color: "base.5", fontWeight: "semi", py: 5 }}>
        Don’t see what you’re looking for? Let us know{" "}
        <Link
          onClick={() => {
            IntercomShowNewMessage(
              `Hi, I would like to request a new destination. I want to sync data to ____ in order to help the _____ team accomplish _____.`,
            );
            analytics.track("Destination Catalog Suggest Destination Clicked", {
              current_category: category,
            });
          }}
        >
          here.
        </Link>
      </Text>
    </Section>
  );
};
