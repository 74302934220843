import { VFC } from "react";

import { Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { Banner } from "src/ui/banner";
import { Button } from "src/ui/button";
import { WarningIcon } from "src/ui/icons";
import { switchWorkspaceRole } from "src/utils/workspaces";

import { warningButtonStyle } from "./workspace-validation";

export const WorkspaceRoleBanner: VFC = () => {
  const { user, workspace } = useUser();

  if (!user) {
    return null;
  }

  const membership_role_id = workspace?.memberships.find((membership) => membership.user_id === user.id)?.role?.id;

  if (user.current_workspace_role_id === membership_role_id) {
    return null;
  }

  const impersontingRole = workspace?.roles.find((role) => role.id === user.current_workspace_role_id);

  function reload() {
    switchWorkspaceRole(membership_role_id);
  }

  return (
    <Banner>
      <WarningIcon color="white" />
      <Text sx={{ fontWeight: "bold", color: "white", fontSize: 1, mx: 4 }}>
        You are impersonting the "{impersontingRole?.name}" role. Be careful.
      </Text>
      <Button sx={warningButtonStyle} variant="secondary" onClick={reload}>
        Revert To Main Role
      </Button>
      <WarningIcon color="white" />
    </Banner>
  );
};
