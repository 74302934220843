import { VFC } from "react";

import { Paragraph } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { WarningIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { Modal } from "src/ui/modal";

import { IntercomShowNewMessage } from "../vendor";

export const OverageModal: VFC = () => {
  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const isOpen = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO once more overages are added, determine the text based on the active overage.
  const { workspace } = useUser();

  const messageIntercom = () => {
    analytics.track("SS Overage Modal Lockout Live Chat Clicked", {
      workspace_id: workspace?.id,
    });
    IntercomShowNewMessage(`Hi, ${overageText.replace(/This/, "my")} I would like to learn more about upgrading.`);
  };

  return (
    <>
      <Modal
        footer={
          <>
            <Button variant="secondary" onClick={() => messageIntercom()}>
              Talk to us
            </Button>
            <Link to="/settings/billing">
              <Button>Go to billing</Button>
            </Link>
          </>
        }
        header={
          <Row gap={3} sx={{ alignItems: "center" }}>
            <WarningIcon color="red" />
            <Heading variant="h2">Billing Alert</Heading>
          </Row>
        }
        isOpen={isOpen}
        sx={{ maxWidth: "500px" }}
        onClose={() => null}
      >
        <Paragraph>{overageText}</Paragraph>
      </Modal>
    </>
  );
};
