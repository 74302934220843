import { VFC, useState, useMemo, useEffect } from "react";

import * as Sentry from "@sentry/browser";
import pluralize from "pluralize";
import { useToasts } from "react-toast-notifications";
import { Text } from "theme-ui";

import { Filters, getHasuaExpFromFilters, sourceFilterDefinitions } from "src/components/filter";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { EditLabels } from "src/components/labels/edit-labels";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteSourcesModal } from "src/components/modals/bulk-delete-sources-modal";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  ConnectionsBoolExp,
  ConnectionsOrderBy,
  ResourcePermissionGrant,
  useAddLabelsToSourcesMutation,
  useDeleteSourcesMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations";
import { Box, Row } from "src/ui/box";
import { Button, DropdownButton } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { ChevronDownIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Menu, MenuOption } from "src/ui/menu";
import { Modal } from "src/ui/modal";
import { Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { Tooltip } from "src/ui/tooltip";
import { useNavigate } from "src/utils/navigate";
import { SourceBadges, SourceIcon, useSources } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";
import { useUser } from "../../contexts/user-context";

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

export const Sources: VFC = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [isConfirmingDeletion, setConfirmingDeletion] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [warningOpen, setWarningOpen] = useState(false);
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);

  const { hasPermission: userCanDelete } = useHasPermission([{ resource: "source", grants: [ResourcePermissionGrant.Delete] }]);

  const { workspace } = useUser();

  const { labels } = useLabels();

  const { mutateAsync: bulkDelete, isLoading: isBulkDeleting } = useDeleteSourcesMutation();
  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToSourcesMutation();

  const { onSort, orderBy } = useTableConfig<ConnectionsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "sources" });

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: ConnectionsBoolExp = {
      _and: [getHasuaExpFromFilters(sourceFilterDefinitions, filters)],
    };

    if (search && Array.isArray(hasuraFilters._and)) {
      hasuraFilters._and.push({
        name: {
          _ilike: `%${search}%`,
        },
      });
    }

    return hasuraFilters;
  }, [filters, search]);

  const { data: allSourcesForFilters } = useSources({ limit: 1000 });

  const { data: allSources, error, loading, isRefetching } = useSources({ filters: hasuraFilters, orderBy });

  const usingDemoSource = useMemo(() => window.localStorage.getItem("useDemoSource"), []);

  const actions: MenuOption[] = [{ label: "Add labels", onClick: () => setAddingLabels(true) }];

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a source, upgrade your plan.";

  if (userCanDelete) {
    actions.push({
      label: "Delete",
      onClick: () => setConfirmingDeletion(true),
      sx: { color: "red", ":hover::not(:disabled)": { backgroundColor: "reds.0" } },
    });
  }

  const deleteSources = async () => {
    if (userCanDelete) {
      try {
        await bulkDelete({ ids: selectedRows.map(String) });
        addToast("Selected sources were deleted.");
        onRowSelect([]);
      } catch (error) {
        addToast("Failed to delete sources.", { appearance: error });
        Sentry.captureException(error);
      }
    }
  };

  const bulkAddLabels = async (labels: Record<string, string>) => {
    const labelCount = Object.keys(labels).length;

    try {
      await Promise.all(
        selectedRows.map((r) => {
          return addLabels({ id: r.toString(), labels });
        }),
      );
      setAddingLabels(false);
      addToast(
        `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "source",
          selectedRows.length,
        )}`,
        {
          appearance: "success",
        },
      );

      onRowSelect([]);
    } catch (error) {
      addToast("Failed to update labels.", {
        appearance: "error",
      });
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    analytics.track("Sources Viewed", {});
  }, []);

  if (loading && !isRefetching) {
    return <PageSpinner />;
  }

  // We only allow to select demo sources if there are no other sources available.
  const nonDemoSources = allSources.filter((source) => !source.is_demo);
  const sources = nonDemoSources.length > 0 || !usingDemoSource ? nonDemoSources : allSources;

  const columns: TableColumn[] = [
    {
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort(SortKeys.Name),
      cell: (source) => {
        return (
          <Row sx={{ alignItems: "center" }}>
            <Tooltip text={source.name}>
              <Text
                sx={{
                  fontWeight: "semi",
                  maxWidth: "350px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {source.name}
              </Text>
            </Tooltip>
            <LabelsCell labels={source.tags} />
            <SourceBadges source={source} />
          </Row>
        );
      },
    },
    {
      name: "Type",
      sortDirection: orderBy?.type,
      onClick: () => onSort(SortKeys.Type),
      cell: (source) => {
        return (
          <Row sx={{ alignItems: "center" }}>
            <SourceIcon source={source} sx={{ width: "18px", mr: 2, flexShrink: 0 }} />
            <Text sx={{ fontWeight: "semi" }}>{source.definition.name}</Text>
          </Row>
        );
      },
    },
    {
      ...LastUpdatedColumn,
      sortDirection: orderBy?.updated_at,
      onClick: () => onSort(SortKeys.UpdatedAt),
    },
  ];

  return (
    <>
      <PermissionProvider permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update] }]}>
        <Page crumbs={[{ label: "Sources" }]} size="full">
          <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8, width: "100%" }}>
            <Row sx={{ alignItems: "center" }}>
              <Heading sx={{ mr: 2 }}>Sources</Heading>
              <Views
                deletePermissionResource="source"
                value={selectedView}
                views={views}
                onChange={selectView}
                onDelete={deleteView}
              />
              {viewNotSaved &&
                (selectedView === "Default view" ? (
                  <Button
                    sx={{ ml: 2 }}
                    variant="purple"
                    onClick={() => {
                      setCreateViewModalOpen(true);
                    }}
                  >
                    Save as
                  </Button>
                ) : (
                  <DropdownButton
                    loading={updatingView}
                    options={[
                      {
                        label: "Save as",
                        onClick: () => {
                          setCreateViewModalOpen(true);
                        },
                      },
                      {
                        label: "Reset changes",
                        onClick: () => {
                          resetViewFilters();
                        },
                      },
                    ]}
                    sx={{ ml: 2 }}
                    onClick={updateCurrentView}
                  >
                    Save
                  </DropdownButton>
                ))}
            </Row>
            <Permission permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}>
              <Button
                disabled={overageLockout}
                tooltip={overageLockout ? overageText : undefined}
                onClick={() => {
                  analytics.track("Add Source Clicked");
                  navigate(`/sources/new`);
                }}
              >
                Add source
              </Button>
            </Permission>
          </Row>
          <Row sx={{ alignItems: "center", mb: 3, width: "100%", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <SearchInput placeholder="Search sources by name..." value={search ?? ""} onChange={setSearch} />

              <Filters
                data={allSourcesForFilters}
                filterDefinitions={sourceFilterDefinitions}
                filters={filters}
                resourceType="source"
                sx={{ ml: 2 }}
                onChange={updateFilters}
              />
            </Box>

            <Fade hidden={selectedRows.length === 0} sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Text as="label" sx={{ display: "flex", alignItems: "center" }}>
                  <Text as="span" sx={{ color: "base.5" }}>{`${pluralize("source", selectedRows.length, true)} selected`}</Text>

                  <Menu options={actions}>
                    <Button
                      propagate
                      iconAfter={<ChevronDownIcon size={16} />}
                      loading={isBulkDeleting}
                      sx={{ ml: 3 }}
                      variant="secondary"
                    >
                      Select action
                    </Button>
                  </Menu>
                </Text>
              </Box>
            </Fade>
          </Row>

          <Table
            columns={columns}
            data={sources}
            error={Boolean(error)}
            loading={isRefetching}
            placeholder={{
              title: "No sources",
              body: search ? "" : "Add a source to get started",
              error: "Sources failed to load, please try again.",
            }}
            selectedRows={selectedRows}
            onRowClick={({ is_demo, id }, event) =>
              is_demo ? setWarningOpen(true) : openUrl(`/sources/${id}`, navigate, event)
            }
            onSelect={onRowSelect}
          />
        </Page>

        <BulkDeleteSourcesModal
          isOpen={isConfirmingDeletion}
          loading={isBulkDeleting}
          sources={selectedRows as string[]}
          workspaceName={workspace?.name ?? ""}
          onCancel={() => {
            setConfirmingDeletion(false);
          }}
          onDelete={deleteSources}
        />
      </PermissionProvider>

      <Modal
        footer={
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setWarningOpen(false);
              }}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                navigate("/sources/new");
              }}
            >
              Add source
            </Button>
          </>
        }
        isOpen={warningOpen}
        title="Demo Source"
        onClose={() => {
          setWarningOpen(false);
        }}
      >
        <Text>This is Hightouch's demo source. Create your own source in less than 5 minutes!</Text>
      </Modal>

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />

      <EditLabels
        description="You can label source that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={loadingAddLabels}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("source", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={bulkAddLabels}
      />
    </>
  );
};
